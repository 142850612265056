import { HubConnectionState } from '@microsoft/signalr';
import Dropdown from 'Common/Dropdown/Dropdown';
import DropdownItem from 'Common/Dropdown/DropdownItem';
import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import { useMobileSideNavContext } from 'Common/MobileSideNavProvider/MobileSideNavProvider';
import AnnouncementDialog from 'Common/Navigation/Component/Announcements/AnnouncementDropDown';
import { useSocketRejoinJoinSales } from 'Common/WebSocketConnection/SignalR/useSocketRejoinSales.hook';
import { useWebSocketConnection } from 'Common/WebSocketConnection/WebSocketProvider';
import { useIsVendor } from 'Features/AuctionControls/auction-controls.hooks';
import TodaysAuctionResults from 'Features/TodaysAuctionResults/TodaysAuctionResults.container';
import { useWatchlistSoundContext } from 'Features/WatchlistSound/WatchlistSoundPovider';
import manheimLogo from 'img/manheim-logo-2.png';
import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

import AlertSoundIcon from '../../Features/WatchlistSound/Components/AlertSoundToggle';
import useShowConnectionDisconnectDialog from '../DialogManager/Components/ConnectionDisconnectDialog/Hooks/useShowConnectionDisconnectDialog.hook';
import { useDialogManagerContext } from '../DialogManager/DialogManagerProvider';
import { DIALOG_TYPES } from '../DialogManager/Enums/dialog-types.enum';
import LogoutButton from '../LogoutButton/LogoutButton';
import PreferencesDropdownIcon from './Component/PreferenceSettingsIcon/PreferencesDropdownIcon';
import ReconnectButton from './Component/ReconnectButton';
import classes from './navigation-header.module.scss';

const Navigation: FC = () => {
    const isMobile = useIsMobile();
    const { toggleMobileSideNav } = useMobileSideNavContext();
    const { isWatchlistSoundActive, toggleWatchlistSound } = useWatchlistSoundContext();
    const { clientLoginInfo: { CN } = {}, userRole } = useSelector((state: AppState) => state.account);
    const { connectionStatus = HubConnectionState.Disconnected, startConnection } = useWebSocketConnection();
    const [isTodaysAuctionVisible, setIsTodaysAuctionVisible] = useState(false);
    const [auctionResultsButtonTitle, setAuctionResultsButtonTitle] = useState(`Show Today's Auction Results`);
    const singalRRejoinSales = useSocketRejoinJoinSales();
    const isVendor = useIsVendor();
    const { showModal } = useDialogManagerContext();
    const isEnableAnnouncements = JSON.stringify(process.env.REACT_APP_ENABLE_ANNOUNCEMENTS) === JSON.stringify('true');

    useShowConnectionDisconnectDialog();

    const handleOpenLogoutDialog = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        showModal(DIALOG_TYPES.LOGOUT_DIALOG, { showLogoutDialog: true });
    };

    const showHideTodaysAuctionResults = () => {
        setAuctionResultsButtonTitle(`${isTodaysAuctionVisible ? 'Show' : 'Hide'} Today's Auction Results`);

        setIsTodaysAuctionVisible(!isTodaysAuctionVisible);
    };

    const restartConnection = () => {
        if (startConnection) {
            startConnection()
                .then(() => {
                    singalRRejoinSales(true);
                })
                .catch((err) => window.logger.error(err));
        }
    };

    useEffect(() => {
        if (isTodaysAuctionVisible && isMobile)
            showHideTodaysAuctionResults();
    }, [isMobile]);

    return (
        <>
            <nav className={classes['nav-header']} id="primary-header">
                {userRole !== '' && isMobile && (
                    <a
                        title="Menu"
                        className={`${classes['mobile-menu-trigger']} ${classes['orientation-hide']}`}
                        onClick={() => toggleMobileSideNav()}></a>
                )}
                {isTodaysAuctionVisible && isVendor && <TodaysAuctionResults />}
                <div className={`${classes.cells} ${isMobile ? classes['header-icons'] : ''}`}>
                    <div className={`${classes.left} ${classes.cell} ${isMobile ? classes['logo-div'] : ''}`}>
                        <img src={manheimLogo} alt="Manheim" className={classes.logo} />
                    </div>
                    {!isMobile && (<div className={`${classes.right} ${classes.cell}`}>
                        <div className={`${classes.secondary} ${classes.cells}`}>
                            {isVendor && (
                                <div className={classes.cell}>
                                    <button type="button" className={classes['auction-result-button']} onClick={showHideTodaysAuctionResults}>
                                        {auctionResultsButtonTitle}
                                    </button>
                                </div>
                            )}

                            {
                                isEnableAnnouncements && <div className={classes.cell}>
                                    <Dropdown title={<PreferencesDropdownIcon title={'What\'s New'} iconName='campaign' />}>
                                        <DropdownItem>
                                            <AnnouncementDialog />
                                        </DropdownItem>
                                    </Dropdown>
                                </div>
                            }

                            <div className={classes.cell}>
                                <Dropdown title={<PreferencesDropdownIcon title={CN} iconName={'person'} color='blue-icon' />} menuTitle={<p className="title">Preferences</p>}>
                                    <DropdownItem>
                                        <AlertSoundIcon isActive={isWatchlistSoundActive} toggleSoundOnClick={toggleWatchlistSound} />
                                    </DropdownItem>
                                </Dropdown>
                            </div>

                            <LogoutButton handleOpenLogoutDialog={handleOpenLogoutDialog} />
                        </div>
                    </div>)}
                </div>
                {userRole !== '' && <ReconnectButton connectionState={connectionStatus} restartConnection={restartConnection} />}{' '}
            </nav>
        </>
    );
};

export default Navigation;
