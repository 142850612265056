import React from 'react';

import classes from '../AdminAnnouncementsForm.module.scss';
import { Announcement } from '../Types/Announcements';

interface AnnouncementFormProps {
    announcement: Announcement;
    onSave: () => void;
    onCancel: () => void;
    onChange: (field: string, value: any) => void;
    formErrors: any;
}

export const AnnouncementForm: React.FC<AnnouncementFormProps> = ({
    announcement,
    onSave,
    onCancel,
    onChange,
    formErrors
}) => {
    const messageTypeOptions = ["News", "Alerts", "Feature"];

    const handleMessageTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onChange('messageType', e.target.value);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        onChange(name, value);
    };

    const handleLinkToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange('linkEnabled', e.target.checked);
    };

    return (
        <form onSubmit={(e) => { e.preventDefault(); onSave(); }} className={classes['form-container']}>
            <h2 className={classes['form-title']}>{announcement.title ? 'Edit Announcement' : 'Create Announcement'}</h2>

            <div>
                <label>Message Type</label>
                <select
                    name="messageType"
                    value={announcement.messageType}
                    onChange={handleMessageTypeChange}
                    className={classes['form-input']}
                >
                    {messageTypeOptions.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                {formErrors.messageType && <p className={classes['error-message']}>{formErrors.messageType}</p>}
            </div>

            <div>
                <label>Title</label>
                <input
                    type="text"
                    name="title"
                    value={announcement.title}
                    onChange={handleInputChange}
                    className={classes['form-input']}
                />
                {formErrors.title && <p className={classes['error-message']}>{formErrors.title}</p>}
            </div>

            <div>
                <label>Description</label>
                <input
                    type="text"
                    name="description"
                    value={announcement.description}
                    onChange={handleInputChange}
                    className={classes['form-input']}
                />
                {formErrors.description && <p className={classes['error-message']}>{formErrors.description}</p>}
            </div>

            <div>
                <label>Order</label>
                <input
                    type="number"
                    name="order"
                    value={announcement.order}
                    onChange={handleInputChange}
                    className={classes['form-input']}
                />
                {formErrors.order && <p className={classes['error-message']}>{formErrors.order}</p>}
            </div>

            <div>
                <label>Expiry Date (optional)</label>
                <input
                    type="date"
                    name="expiryDate"
                    value={announcement.expiryDate || ''}
                    onChange={handleInputChange}
                    className={classes['form-input']}
                />
                {formErrors.expiryDate && <p className={classes['error-message']}>{formErrors.expiryDate}</p>}
            </div>

            <div>
                <label>Link</label>
                <input
                    type="text"
                    name="link"
                    value={announcement.link}
                    onChange={handleInputChange}
                    className={classes['form-input']}
                />
            </div>

            <div className={classes['enable-link-checkbox']}>
                <label>Enable Link</label>
                <input
                    type="checkbox"
                    name="linkEnabled"
                    checked={announcement.linkEnabled}
                    onChange={handleLinkToggle}
                    className={classes['form-input']}
                />
            </div>

            <div className={classes['button-container']}>
                <button type="submit" className={classes['save-button']}>
                    {announcement.title ? 'Save Changes' : 'Create'}
                </button>
                <button
                    type="button"
                    onClick={onCancel}
                    className={classes['cancel-button']}
                >
                    Cancel
                </button>
            </div>
        </form>
    );
};