import React from 'react';

import classes from '../AdminAnnouncementsForm.module.scss';
import { Announcement } from '../Types/Announcements';

interface AnnouncementCardProps {
    announcement: Announcement;
    onDelete: () => void;
    onEdit: () => void;
}

export const AnnouncementCard: React.FC<AnnouncementCardProps> = ({
    announcement,
    onDelete,
    onEdit
}) => {
    return (
        <div className={classes['announcement-card']}>
            <h3>{announcement.title}</h3>
            <p><strong>Type:</strong> {announcement.messageType}</p>
            <p><strong>Description:</strong> {announcement.description?.substring(0, 50)}...</p>
            <div>
                <button onClick={onEdit} className={classes['edit-button']}>Edit</button>
                <button onClick={onDelete} className={classes['delete-button']}>Delete</button>
            </div>
        </div>
    );
};