import { useEffect, useState } from 'react';

import { Announcement } from '../Types/Announcements';

export const useAnnouncements = () => {
    const [announcements, setAnnouncements] = useState<Announcement[]>([]);

    const fetchAnnouncements = async () => {
        const apiUrl = process.env.REACT_APP_SIMULCAST_API || 'https://localhost:44339';
        const getAnnouncementsEndpoint = process.env.REACT_APP_GET_ALL_ANNOUNCEMENTS_ENDPOINT;

        const response = await fetch(`${apiUrl}${getAnnouncementsEndpoint}`);
        if (!response.ok) throw new Error('Failed to fetch announcements');
        const data = await response.json();

        setAnnouncements(data);
    };

    const deleteAnnouncement = async (id: number) => {
        const apiUrl = process.env.REACT_APP_SIMULCAST_API || 'https://localhost:44339';
        const deleteAnnouncementsEndpoint = process.env.REACT_APP_DELETE_ANNOUNCEMENT_ENDPOINT;

        try {
            const response = await fetch(`${apiUrl}${deleteAnnouncementsEndpoint}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(id),
            });

            if (!response.ok) throw new Error('Failed to delete announcement');
            await fetchAnnouncements();
        } catch (error) {

        }
    };

    useEffect(() => {
        fetchAnnouncements();
    }, []);

    return { announcements, fetchAnnouncements, deleteAnnouncement };
};