import { useState } from 'react';

import { NEWS } from '../../../../../../Domain/Constants/general-constants'
import { Announcement } from '../Types/Announcements';

const defaultAnnouncement: Announcement = {
    id: 0,
    messageType: NEWS,
    title: '',
    description: '',
    link: '',
    order: 0,
    expiryDate: '',
    Active: true,
    Deleted: false,
    linkEnabled: false,
};

export const useAnnouncementForm = (onSaveSuccess: () => void) => {
    const [announcement, setAnnouncement] = useState<Announcement>(defaultAnnouncement);
    const [formErrors, setFormErrors] = useState<any>({});
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState<number | null>(null);

    const validateForm = (): boolean => {
        const errors: any = {};
        const { title, description, messageType, order, expiryDate, link } = announcement;

        if (!title || title.length > 50) {
            errors.title = 'Title is required and must be less than 50 characters';
        }

        if (!description || description.length === 0) {
            errors.description = 'Description is required';
        }

        if (order <= 0) {
            errors.order = 'Order is required and must be a positive number';
        }

        if (expiryDate && isNaN(new Date(expiryDate).getTime())) {
            errors.expiryDate = 'Expiry Date must be a valid date';
        }

        if (link && link.length > 1024) {
            errors.link = 'Link must be less than 1024 characters';
        }

        if (messageType === '') {
            errors.messageType = 'Message Type is required';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSave = async () => {
        if (!validateForm()) return;

        const apiUrl = process.env.REACT_APP_SIMULCAST_API || 'https://localhost:44339';
        const setAnnouncementEndpoint = process.env.REACT_APP_SET_ANNOUNCEMENT_ENDPOINT;
        const body = {
            ...announcement,
            Id: isEditing ? editId : undefined,
        };

        const response = await fetch(`${apiUrl}${setAnnouncementEndpoint}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        });

        if (!response.ok) throw new Error('Failed to save announcement');
        onSaveSuccess();
        resetForm();
    };

    const resetForm = () => {
        setAnnouncement(defaultAnnouncement);
        setIsEditing(false);
        setEditId(null);
        setFormErrors({});
    };

    const startEditing = (existingAnnouncement: Announcement) => {
        setIsEditing(true);
        setEditId(existingAnnouncement.id);

        const formattedExpiryDate = existingAnnouncement.expiryDate
            ? new Date(existingAnnouncement.expiryDate).toISOString().split('T')[0]
            : '';

        setAnnouncement({
            ...existingAnnouncement,
            expiryDate: formattedExpiryDate
        });
    };

    return {
        announcement,
        setAnnouncement,
        formErrors,
        isEditing,
        handleSave,
        resetForm,
        startEditing
    };
};