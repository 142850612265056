import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

import { NEWS } from '../../../../Domain/Constants/general-constants'
import classes from './announcement-dropdown.module.scss';

export interface Announcement {
    type: string;
    title?: string;
    description?: string;
    link?: string;
    linkEnabled?: boolean; 
    order: number;
}

const AnnouncementDialog: FC = () => {
    const [announcements, setAnnouncements] = useState<Announcement[]>([]);

    const { account: { permissions: { announcementsManager = false } = {} } = {} } = useSelector((state: AppState) => state);

    useEffect(() => {
        const fetchAnnouncements = async () => {
            const apiUrl = process.env.REACT_APP_SIMULCAST_API || 'https://localhost:44339';
            const getAnnouncementsEndpoint = process.env.REACT_APP_GET_ALL_ANNOUNCEMENTS_ENDPOINT;

            const response = await fetch(`${apiUrl}${getAnnouncementsEndpoint}`);
            if (!response.ok)
                throw new Error('Failed to fetch announcements');
            const data = await response.json();

            const sortedAnnouncements = data
                .map((announcement: any) => ({
                    type: announcement.messageType || NEWS,
                    title: announcement.title,
                    description: announcement.description,
                    link: announcement.link,
                    linkEnabled: announcement.linkEnabled, 
                    order: announcement.order || 0,
                }))
                .sort((a, b) => a.order - b.order);

            setAnnouncements(sortedAnnouncements);
        };

        fetchAnnouncements();
    }, []);

    if (!announcements.length) {
        return (
            <div className={classes.container}>
                {announcementsManager && (
                    <a href="/admin/announcements" className={classes['btn-edit-announcements']} title="Edit Announcements">
                        Edit Announcements
                    </a>
                )}
                <div>No Announcements</div>
            </div>)
    }

    const navigateToTheLink = (link = '') => {
        if (link.length) {
            if (!link.startsWith('http://') && !link.startsWith('https://')) {
                link = 'https://' + link;
            }

            window.open(link, '_blank');
        }
    };

    return (
        <div className={classes.container}>
            {announcementsManager && (
                <a href="/admin/announcements" className={classes['btn-edit-announcements']} title="Edit Announcements">
                    Edit Announcements
                </a>
            )}
            {announcements.map(({ type = NEWS, title = '', description = '', link = '', linkEnabled = false }, index) => (
                <div key={index} className={classes['announcement-row']} onClick={() => navigateToTheLink(link)}>
                    <div className={classes['announcement-type']}>{type}</div>
                    <b>{title}</b>
                    <div className={classes['announcement-description']}>{description}</div>
                    {link && linkEnabled && ( 
                        <span className={classes['btn-view-now']} title="Learn More">
                            LEARN MORE
                        </span>
                    )}
                </div>
            ))}
        </div>
    );
};

export default AnnouncementDialog;