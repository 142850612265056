import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppState } from 'services/redux-store';

import classes from '../AdminAnnouncementsForm.module.scss';
import { useAnnouncements } from '../Hooks/useAnnouncements';
import { useAnnouncementForm } from '../Hooks/useAnnouncementsForm';
import { AnnouncementCard } from './AnnouncementCard';
import { AnnouncementForm } from './AnnouncementForm';

export const AdminAnnouncementsPage: React.FC = () => {
    const { announcementsManager = false } = useSelector((state: AppState) =>
        state.account?.permissions ?? {});
    const [isFormVisible, setIsFormVisible] = useState(false);

    const { announcements, fetchAnnouncements, deleteAnnouncement } = useAnnouncements();
    const {
        announcement,
        setAnnouncement,
        formErrors,
        handleSave,
        resetForm,
        startEditing
    } = useAnnouncementForm(() => {
        fetchAnnouncements();
        setIsFormVisible(false);
    });

    if (!announcementsManager) {
        return <div>You do not have permission to view this page.</div>;
    }

    const navigate = useNavigate();

    const handleSaveAnnouncement = () => {
        if (!announcement.expiryDate) {
            setAnnouncement((prev) => ({ ...prev, expiryDate: null }));
        }

        handleSave();
    };

    return (
        <div className={classes['announcement-page']}>
            <h2>Admin Announcements</h2>

            <div className={classes['buttons-container']}>
                {!isFormVisible && (
                    <>
                        <button
                            onClick={() => setIsFormVisible(true)}
                            className={classes['add-button']}
                        >
                            Create New
                        </button>
                        <button
                            onClick={() => navigate('/buyer')}
                            className={classes['done-button']}
                        >
                            Return to Simulcast
                        </button>
                    </>
                )}
            </div>
            {isFormVisible && (
                <AnnouncementForm
                    announcement={announcement}
                    onSave={handleSaveAnnouncement}
                    onCancel={() => {
                        resetForm();
                        setIsFormVisible(false);
                    }}
                    onChange={(field, value) =>
                        setAnnouncement(prev => ({ ...prev, [field]: value }))}
                    formErrors={formErrors}
                />
            )}

            <div className={classes['existing-announcements']}>
                <h3>Existing Announcements</h3>
                {announcements.length === 0 && <p>No announcements found</p>}
                {announcements.map((announcement) => (
                    <AnnouncementCard
                        key={announcement.id}
                        announcement={announcement}
                        onDelete={() => deleteAnnouncement(announcement.id)}
                        onEdit={() => {
                            startEditing(announcement);
                            setIsFormVisible(true);
                        }}
                    />
                ))}
            </div>
        </div>
    );
}